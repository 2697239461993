@import '../../../styles/styles.scss';

.tag {
  @include mulish-font($size: 14px, $color: var(--bw-color));
  align-items: center;
  background-color: var(--grey-color);
  border-radius: 5px;
  display: flex;
  margin-right: 5px;
  margin-top: 10px;
  padding: 5px;

  > svg {
    cursor: pointer;
    margin-left: 10px;
  }
}

.tagsWrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.filterWrapper {
  align-items: flex-start;
  flex-direction: column;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &:hover {
      > p:last-child {
        display: block;
      }
    }

    > p {
      &:first-child {
        @include mulish-font($size: 12px, $color: var(--bw-color));
      }

      &:last-child {
        @include mulish-font($size: 12px, $color: var(--gold-color));
        cursor: pointer;
        display: none;
        text-decoration: underline;
        text-transform: uppercase;
      }
    }
  }
}
