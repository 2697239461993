@import '../../../styles/styles.scss';

.wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  > p {
    @include mulish-font($size: 12px, $color: var(--bw-color));
    margin-bottom: 20px;
  }
}
