@import '../../../styles/styles.scss';

.button {
  @include mulish-font($size: 14px, $color: var(--bw-color));
  background-color: var(--grey-color);
  border: unset;
  border-radius: var(--app-border-radius);
  box-shadow: none;
  cursor: pointer;
  height: 30px;
  width: 155px;

  &--is-active {
    background-color: var(--bw-color);
    color: var(--white-color);
  }
}
