*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  background-color: var(--color-primary);
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  line-height: 1.5;
  color: #333333;
}