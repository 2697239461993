@import '../../../styles/styles.scss';

.slider {
  padding: 0 10px;

  &__handlerNumber {
    @include mulish-font($size: 14px, $color: var(--bw-color));
    display: flex;
    margin-top: 10px;
  }
}

[class*='rc-slider-handle'] {
  &:active {
    box-shadow: 0 0 5px var(--gold-color) !important;
  }
}
