@import '../../../styles/styles.scss';

.css-checkbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.css-checkbox + label {
  @include mulish-font($size: 12px, $color: var(--bw-color), $type: 'regular');
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 20px;
}

.css-checkbox + label::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: var(--bw-color);
  border-radius: 3px;
  box-shadow: none;
}

.css-checkbox:checked + label::after {
  content: ' ';
  background: var(--bw-color);
  border-radius: 1px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  left: 3px;
  top: 5px;
  text-align: center;
  font-size: 10px;
  height: 10px;
  width: 10px;
}
