@import '../../../styles/styles.scss';

.calendar {
  background-color: var(--grey-color);
  border: unset;
  display: flex;
  justify-content: center;
  padding: 20px 14px;
  width: 100%;

  [class*='react-datepicker__header'] {
    background-color: var(--grey-color);
    border: unset;
  }

  [class*='react-datepicker__day'] {
    @include mulish-font($size: 10px, $color: var(--bw-color), $type: 'regular');
  }

  [class*='react-datepicker__day-name'] {
    @include mulish-font($size: 12px, $color: var(--bw-color), $type: 'bold');
  }

  [class*='react-datepicker__day--keyboard-selected'] {
    background-color: var(--bw-color);
    border-radius: 50%;
    color: var(--white-color);
  }

  [class*='react-datepicker__day--in-range'] {
    background-color: var(--gold-color);
    border-radius: 50%;
    color: var(--bw-color);
  }

  [class*='react-datepicker__day--selected'] {
    background-color: var(--gold-color);
    border-radius: 50%;
    color: var(--bw-color);
  }

  [class*='react-datepicker__day--in-selecting-range'] {
    background-color: var(--gold-opaque-color);
    border-radius: 50%;
    color: var(--bw-color);
  }
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  margin-bottom: 20px;
}

.header-navigation {
  display: flex;
  justify-content: space-between;

  > p {
    @include mulish-font($size: 12px, $color: var(--bw-color), $type: 'regular');
    align-items: center;
    display: flex;
    margin: 0;
  }

  > svg {
    cursor: pointer;
  }
}
