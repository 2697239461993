.input {
  width: 40px;

  input {
    text-align: left;
  }
}

.selection {
  align-items: center;
  display: flex;
  margin-top: 10px;
}
