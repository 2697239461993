$paddingTop: 50px;

.wrapper {
  height: 100vh;
}

.content {
  align-items: center;
  display: flex;
  padding: $paddingTop 36px 0 36px;
}

.filters {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{var(--navbar-height)} - #{$paddingTop});
  max-height: calc(100vh - #{var(--navbar-height)} - #{$paddingTop});
  max-width: 300px;
  overflow-y: auto;
  padding-bottom: 30px;
  position: relative;
  width: 30%;
}

.list {
  border-left: 1px solid var(--light-grey-color);
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{var(--navbar-height)} - #{$paddingTop});
  max-height: calc(100vh - #{var(--navbar-height)} - #{$paddingTop});
  overflow-y: hidden;
  padding-bottom: 30px;
  padding-left: 35.5px;
  width: 100%;
}
