@import '../../../styles/styles.scss';

.localeDetails {
  width: 555px;

  &__button {
    color: var(--error-color);
  }

  &__content {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__email {
    margin-bottom: 50px;
    text-decoration: underline;
  }
  &__header {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
  }
  h1 {
    @include mulish-font($size: 16px, $color: var(--bw-color), $type: 'bold');
    margin-right: 11px;
  }

  hr {
    background-color: var(--grey-medium-color);
    border: unset;
    height: 1px;
    margin: 20px 0;
  }

  p {
    @include mulish-font($size: 14px, $color: var(--bw-color), $type: 'regular');
    ~ p {
      margin-top: 10px;
    }
  }
  &__status {
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
  &__text {
    margin-top: 0;
    margin-bottom: 15px;
    &:last-of-type {
      margin: 0;
    }
  }
}
