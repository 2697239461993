$padding: 35px;

.close {
  cursor: pointer;
  position: absolute;
  right: $padding;
  top: $padding;
}

.content {
  background-color: var(--white-color);
  border-radius: 5px;
  padding: $padding;
  position: relative;
}

.wrapper {
  align-items: center;
  background-color: var(--light-grey-opaque-color);
  backdrop-filter: blur(2px);
  display: flex;
  height: calc(100vh - #{var(--navbar-height)});
  justify-content: center;
  left: 0;
  position: fixed;
  top: var(--navbar-height);
  width: 100vw;
}
