@import '../../../styles/styles.scss';

.button {
  justify-content: center;
}

.overlay {
  background: white;
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% - 35px);
  z-index: 2;
}
