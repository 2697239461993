:root {
  --active-color: rgb(86 146 53);
  --bw-color: #161515;
  --dark-gold-color: rgb(225 169 60);
  --error-color: #c43535;
  --gold-color: #e7bf62;
  --gold-opaque-color: #e7bf627e;
  --grey-color: #f5f4f1;
  --grey-medium-color: #e9e9e9;
  --light-gold-color: rgb(241 216 164);
  --light-grey-color: rgb(232 230 225);
  --light-grey-opaque-color: rgba(232, 230, 225, 0.71);
  --white-color: #fff;

  --app-border-radius: 5px;
  --navbar-height: 80px;
}
