@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Mulish:wght@400;500;700&display=swap');
@import './functions';

@mixin montserrat-font($size: 12px, $bolded: false, $color: #151515) {
  @if $bolded == true {
    font-weight: bold;
  }

  color: $color;
  font-family: 'Montserrat', sans-serif;
  font-size: calculateRem($size);
}

@mixin mulish-font($size: 12px, $color: #151515, $type: 'regular') {
  @if $type == 'regular' {
    font-weight: 400;
  }

  @if $type == 'bold' {
    font-weight: 700;
  }

  @if $type == 'medium' {
    font-weight: 500;
  }

  color: $color;
  font-family: 'Mulish', sans-serif;
  font-size: calculateRem($size);
}
