@import '../../../styles/styles.scss';

.actions {
  align-items: center;
  display: flex;
}
.button {
  height: 40px;
  @include mulish-font($size: 14px, $color: var(--bw-color), $type: 'bold');
}

.wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.title {
  @include mulish-font($size: 20px, $color: var(--bw-color), $type: 'bold');
}
