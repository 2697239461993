@import '../../../styles/styles.scss';

.wrapper {
  @include mulish-font($size: 12px, $color: var(--bw-color), $type: 'regular');

  align-items: center;
  border: 1px solid var(--light-grey-color);
  border-radius: 5px;
  display: flex;
  height: 40px;
  padding: 10px;
  width: 100%;

  > svg {
    margin-right: 10px;
  }

  > input {
    @include mulish-font($size: 14px, $color: var(--bw-color), $type: 'regular');

    border: unset;
    display: flex;
    flex: 1;
    max-width: 100%;
    outline: none;
  }
}
