@import '../../../styles/styles.scss';

.customerDetails {
  width: 300px;

  a {
    @include mulish-font($size: 12px, $color: var(--bw-color));
    text-decoration: underline;
  }

  h1 {
    @include mulish-font($size: 16px, $color: var(--bw-color), $type: 'bold');
    margin-right: 10px;
  }

  hr {
    background-color: var(--grey-medium-color);
    border: unset;
    height: 1px;
    margin: 20px 0;
  }

  p {
    @include mulish-font($size: 12px, $color: var(--bw-color));

    ~ p {
      margin-top: 10px;
    }
  }
  &__button_red {
    color: var(--error-color);
    margin-top: 18px;
  }
  &__button_green {
    color: var(--active-color);
    margin-top: 18px;
  }
  &__status {
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
  &__title {
    align-items: center;
    display: flex;
  }
}
