@import '../../../styles/styles.scss';
.table {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;

  > thead {
    > tr {
      display: flex;
      border-bottom: 1px solid var(--bw-color);
      padding-bottom: 10px;
      justify-content: space-between;
      th {
        @include mulish-font($size: 12px, $color: var(--bw-color), $type: 'bold');
        text-align: left;

        &[data-sortable='true'] {
          cursor: pointer;
        }

        > div {
          > svg {
            margin-left: 10px;

            &[data-sort-direction='asc'] {
              transform: rotate(180deg);
            }
          }
        }
      }
      th:first-of-type {
        > div {
          width: 286px;
        }
      }
      th:nth-child(2) {
        > div {
          width: 26px;
        }
      }
      th:nth-child(3) {
        > div {
          width: 100px;
        }
      }
      th:nth-child(4) {
        > div {
          width: 160px;
        }
      }
      th:nth-child(5) {
        > div {
          width: 60px;
        }
      }
      th:nth-child(6) {
        > div {
          width: 400px;
        }
      }
      th:nth-child(7) {
        > div {
          width: 150px;
        }
      }
    }
  }
  > tbody {
    display: block;
    height: calc(100vh - 276.5px);
    overflow-y: scroll;

    > tr {
      height: 50px;
      border-bottom: 1px solid var(--grey-medium-color);
      display: flex;
      justify-content: space-between;
      align-items: center;
      td {
        @include mulish-font($size: 12px, $color: var(--bw-color), $type: 'regular');
        text-align: left;

        > span {
          padding: 5px 0;
        }

        &:first-child {
          > span:hover {
            background-color: var(--grey-color);
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
  p.active {
    color: #569235;
    display: inline-block;
  }
  p.none {
    color: #c43535;
    display: inline-block;
  }
  &__active {
    background-color: #569235;
  }
  &__none {
    background-color: #c43535;
  }
  &__status {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
  }
}
.thForLocals {
  &:first-of-type {
    flex: 1 1 0%;
  }
  &:nth-child(2) {
    width: 155px;
  }
  &:nth-child(3) {
    width: 130px;
  }
  &:nth-child(4) {
    width: 200px;
  }
}
