@import '../../../styles/styles.scss';

.wrapper {
  align-items: center;
  background-color: var(--bw-color);
  display: flex;
  height: var(--navbar-height);
  flex: 1;
  justify-content: space-between;
  padding: 0px 36px;

  &__logout {
    align-items: center;
    cursor: pointer;
    display: flex;
  }
  &__logoContainer {
    align-items: center;
    display: flex;

    > img {
      margin-bottom: 9px;
      margin-right: 15px;
      width: 35px;
    }
  }
  &__logoutLabel {
    @include mulish-font($size: 16px, $color: var(--white-color));
    margin-right: 15px;
  }

  &__title {
    @include montserrat-font($size: 16px, $color: var(--gold-color));
  }
}
