@import '../../../styles/styles.scss';

.wrapper {
  @include mulish-font($size: 12px, $color: var(--bw-color), $type: 'bold');

  align-items: center;
  border: unset;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  height: 30px;
  min-width: fit-content;
  padding: 5px 14px;
  width: 100%;

  &[data-type='default'] {
    background-color: var(--grey-color);
  }

  &[data-type='active'] {
    background-color: var(--gold-color);
  }

  &[data-type='confirm'] {
    background-color: var(--bw-color);
    color: var(--white-color);
  }
  &[data-type='disabled'] {
    background: linear-gradient(to bottom, var(--grey-medium-color), #b7b7b7) !important;
    border: 1px solid #aeaeae !important;
    color: var(--white-color) !important;
    cursor: not-allowed;

    &:hover {
      background: linear-gradient(to bottom, var(--grey-medium-color), #b7b7b7) !important;
      border: 1px solid #aeaeae !important;
    }
  }
  &[data-type='text'] {
    background-color: transparent;
    border: none;
    min-width: auto;
    padding: 0;
    width: auto;

    &:hover {
      background-color: transparent;
      border: none;
    }
  }
  & > svg {
    margin-right: 5px;
  }
}
