@import '../../../styles/styles.scss';

.registerButton {
  background: linear-gradient(to bottom, rgb(241 216 164), rgb(225 169 60)) !important;
  background-color: var(--gold-color);
  color: var(--white-color) !important;
  height: 40px !important;
  justify-content: center;
}

.wrapper {
  width: 250px;

  h1 {
    @include mulish-font($size: 16px, $color: var(--bw-color), $type: 'bold');
  }

  &__error {
    @include mulish-font($size: 12px, $color: var(--error-color), $type: 'bold');
    text-align: center;
  }
}
