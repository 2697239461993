@import '../../../styles/styles.scss';

$borderRadius: 5px;
$padding: 10px;
$selectHeight: 30px;

.select {
  @include mulish-font($size: 12px, $color: var(--bw-color), $type: 'regular');
  align-self: center;
  border: 1px solid var(--light-grey-color);
  border-radius: $borderRadius;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  height: $selectHeight;
  justify-content: space-between;
  padding: 0 $padding;
  position: relative;

  > span {
    align-items: center;
    display: flex;
  }

  > svg {
    margin-top: 4px;
    transform: rotate(-90deg);
    transition: transform 500ms ease-in-out;
  }

  &__options {
    align-items: flex-start;
    background-color: var(--white-color);
    border: 1px solid var(--light-grey-color);
    border-radius: $borderRadius;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    left: 0;
    padding: $padding;
    position: absolute;
    top: calc(#{$selectHeight} + 10px);
    user-select: none;
    width: min-content;

    &__option {
      display: flex;
      justify-content: flex-start;
      padding: 5px 0;
      text-align: left;
      width: 100%;
    }
  }

  &--is-opened {
    .select__options {
      display: flex;
    }

    > svg {
      transform: rotate(90deg);
    }
  }
}
